<template>
    <div class="login-container"  v-loading="loading">
      <div class="login-content"  >
        <div class="login_left_img">
          <!-- <img src="@/assets/login/login_left_img.png" class="img" alt /> -->
          <img src="@/assets/fengmain.gif" class="img" alt />
        </div>
        <div class="login_right_form" v-loading="loading" >
          <div class="login_logo_title">智慧用电平台</div>
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            label-width="94px"
          >
            <el-form-item prop="loginId" label="账号 :">
              <el-input
                v-model="loginForm.loginId"
                placeholder="请输入账号"
                type="text"
                class="input_form"
                 @keydown.native.enter="submitForm('loginForm')"
              />
            </el-form-item>

            <el-form-item prop="password" label="密码 :">
              <el-input
                v-model="loginForm.password"
                type="password"
                class="input_form"
                placeholder="请输入密码"
                 @keydown.native.enter="submitForm('loginForm')"
              />
            </el-form-item>
            <el-form-item prop="password" label='验证码 :'>
              <div class="code-box">
              <el-input
                v-model="loginForm.verifyCode"
                type="text"
                class="input_form"
                placeholder="请输入验证码"
                 @keydown.native.enter="submitForm('loginForm')"
              />
              <img  @click="updateVerifyCode" :src="codeImg" class='code-img' alt="" />
              </div>

            </el-form-item>
          </el-form>

          <el-button type="primary" class="login_btn" @click='submitForm("loginForm")'>登录</el-button>
        </div>
        <div class="company-name">河北旭强智能科技有限公司</div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'login',
  data () {
    return {
      loginForm: {
        loginId: '',
        password: '',
        verifyCode: ''
      },
      codeImg: this.$apis.common.getVerifyCode + '?time=' + Number(new Date()),
      loginRules: {
        loginId: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },

      loading: false
    }
  },

  methods: {
    updateVerifyCode () {
      this.codeImg = this.$apis.common.getVerifyCode + '?time=' + Number(new Date())
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$axios.post(this.$apis.common.login, this.loginForm).then(res => {
            console.log(res)
            this.$store.commit('setToken', res.token)
            this.$store.commit('setUser', res.user)
            this.$router.push('/index')
            this.$event.$emit('loadMsg')
          }).catch(() => {
            this.updateVerifyCode()
            this.loginForm.verifyCode = ''
          }).finally(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.login-container {
  width: 100%;
  height: 100vh;
  position: relative;
  width: 100%;
  min-width: 1200px;
  min-height: 500px;
  // background: url('../../assets/login/login_page_bg.png') no-repeat left top;
  background-color: #007165;
  background-size: 100% 100%;
  .login-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -240px;
    margin-left: -437px;
    width: 875px;
    height: 480px;
    border-radius: 13px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .login_left_img {
      width: 481px;
      height: 481px;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .login_right_form {
      flex: 1;
      width: 330px;
      padding: 70px 30px 0 30px;
      height: 100%;
      box-sizing: border-box;
      ::v-deep .login-form {
         .el-form-item__label {
          font-size: 18px;
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 500;
          color: #333333;
          line-height: 40px;
        }
        .el-form-item{
          margin-bottom: 25px;
        }
        .input_form {
          height: 40px;
         .el-input__inner {
            height: 100%;
            line-height: 40px;
          }
        }
      }
      .code-box{
        position: relative;
        .code-img{
          width: 90px;
          height: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          top:0;
        }
      }
      .login_logo_title {
        font-size: 32px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        margin-bottom: 50px;
      }
      .login_btn {
        width: 100%;
        margin-top: 20px;
        height:40px;
      }
    }

    .company-name {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      font-size: 13px;
    }
  }
}
</style>
